import { SVGProps } from 'react';

export const SvgCheckSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M1.36136 1.36136C2.33985 0.382871 3.81241 0 5.74118 0H10.2588C12.1876 0 13.6601 0.382871 14.6386 1.36136C15.6171 2.33985 16 3.81241 16 5.74118V10.2588C16 12.1876 15.6171 13.6601 14.6386 14.6386C13.6601 15.6171 12.1876 16 10.2588 16H5.74118C3.81241 16 2.33985 15.6171 1.36136 14.6386C0.382871 13.6601 0 12.1876 0 10.2588V5.74118C0 3.81241 0.382871 2.33985 1.36136 1.36136ZM2.02687 2.02687C1.31125 2.7425 0.941177 3.90524 0.941177 5.74118V10.2588C0.941177 12.0948 1.31125 13.2575 2.02687 13.9731C2.7425 14.6888 3.90524 15.0588 5.74118 15.0588H10.2588C12.0948 15.0588 13.2575 14.6888 13.9731 13.9731C14.6888 13.2575 15.0588 12.0948 15.0588 10.2588V5.74118C15.0588 3.90524 14.6888 2.7425 13.9731 2.02687C13.2575 1.31125 12.0948 0.941177 10.2588 0.941177H5.74118C3.90524 0.941177 2.7425 1.31125 2.02687 2.02687Z" />
  </svg>
);
