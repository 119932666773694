import React, { Suspense, useEffect, useRef, useState } from 'react';

export default function LottieAnimation({
  animation,
  className = '',
}: {
  animation: string;
  className?: string;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(animation);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error('Error fetching animation data:', error);
      }
    };

    fetchAnimationData();
  }, [animation]);

  const LottieRenderer = React.lazy(async () => {
    const lottie = (await import('lottie-web')).default;

    return {
      default: function LottieContainer() {
        useEffect(() => {
          let animationInstance: any;
          if (animationData && containerRef.current) {
            animationInstance = lottie.loadAnimation({
              container: containerRef.current,
              animationData: animationData,
              renderer: 'svg',
              loop: true,
              autoplay: true,
            });
          }

          return () => {
            if (animationInstance) {
              animationInstance.destroy();
            }
          };
        }, [animationData]);

        return <div ref={containerRef} className={className} />;
      },
    };
  });

  return (
    <Suspense>
      <LottieRenderer />
    </Suspense>
  );
}
