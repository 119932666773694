import { SVGProps } from 'react';

export const SvgSad = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={32}
    width={32}
    fill="currentColor"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M16 32C7.21569 32 0 24.7984 0 15.9687C0 7.13894 7.21569 0 16 0C24.7843 0 32 7.20157 32 15.9687C32 24.7358 24.8471 32 16 32ZM16 2.00391C8.28235 2.00391 1.9451 8.26615 1.9451 16.0313C1.9451 23.7965 8.21961 30.0587 16 30.0587C23.7804 30.0587 30.0549 23.7965 30.0549 16.0313C30.0549 8.26615 23.7804 2.00391 16 2.00391ZM23.4667 22.9198C23.9686 22.6693 24.1569 22.0431 23.9059 21.6047C23.8431 21.4168 21.5843 17.0333 16 17.0333C10.4157 17.0333 8.15686 21.4168 8.09412 21.6047C7.84314 22.1057 8.03137 22.6693 8.53333 22.9198C9.03529 23.1703 9.6 22.9824 9.85098 22.4814C9.91373 22.3562 11.6706 19.0372 16 19.0372C20.3294 19.0372 22.0235 22.3562 22.0863 22.4814C22.2745 22.8571 22.5882 23.045 22.9647 23.045C23.2157 22.9824 23.3412 22.9824 23.4667 22.9198ZM21.0196 14.0274C19.8902 14.0274 19.0118 13.1507 19.0118 12.0235C19.0118 10.8963 19.8902 10.0196 21.0196 10.0196C22.149 10.0196 23.0275 10.8963 23.0275 12.0235C23.0275 13.1507 22.149 14.0274 21.0196 14.0274ZM11.0431 14.0274C9.91373 14.0274 9.03529 13.1507 9.03529 12.0235C9.03529 10.8963 9.91373 10.0196 11.0431 10.0196C12.1725 10.0196 13.051 10.8963 13.051 12.0235C13.051 13.1507 12.1098 14.0274 11.0431 14.0274Z" />
  </svg>
);
