import { SVGProps } from 'react';

export const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4462 1.84615C6.14422 1.84615 1.84615 6.14422 1.84615 11.4462C1.84615 16.7481 6.14422 21.0462 11.4462 21.0462C16.7481 21.0462 21.0462 16.7481 21.0462 11.4462C21.0462 6.14422 16.7481 1.84615 11.4462 1.84615ZM0 11.4462C0 5.12462 5.12462 0 11.4462 0C17.7677 0 22.8923 5.12462 22.8923 11.4462C22.8923 17.7677 17.7677 22.8923 11.4462 22.8923C5.12462 22.8923 0 17.7677 0 11.4462Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2088 20.2088C20.5693 19.8483 21.1538 19.8483 21.5143 20.2088L23.7296 22.4242C24.0901 22.7847 24.0901 23.3692 23.7296 23.7296C23.3692 24.0901 22.7847 24.0901 22.4242 23.7296L20.2088 21.5143C19.8483 21.1538 19.8483 20.5693 20.2088 20.2088Z"
    />
  </svg>
);
